import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import styles from '.././global.css'
import '.././movil.css'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" class="section-content">
                <div class="region-inner region-content-inner">
                    <div class="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                    <div id="page4" className="page page4 pdetalles" >
                        <div className="inner">
                            <span className="bg1"></span>
                            <span className="bg2"></span>
                            <span className="bglogo2"><span className="logoimg"></span></span>
                            <div className="row">
                                <div className="col cleft">
                                    <div className="imagen">
                                        <div className="boton"><a href="https://autodios.github.io/dist/" target="_blank">Ir a la exhibición</a></div>
                                    </div>
                                </div>
                                <div className="col cright">
                                    <div className="artista"><img src={Image1} /></div>
                                    <div className="titulo">
                                        <div className="nombre"><b>Paola Torres Núñez del Prado</b></div>
                                        <div className="obra"><b>Obra:</b> Expansión (como todo)</div>
                                        <div className="obra"><b>Composición musical:</b> Luxsie</div>
                                    </div>
                                    <div className="detalle">
                                        <p>Biografía<br />Paola Torres Núñez del Prado explora los límites entre el audio y lo visual, la voz humana, los sonidos de la naturaleza (agua, viento), y aquellos cuya escucha a menudo se considera menos armoniosa, como los sonidos de las máquinas, el ruido, o los sonidos generados digitalmente. Por medio de una investigación transdisciplinaria, que incluye tanto el diseño de interfases disruptivas, como su propio cuerpo, examina los límites de los sentidos como método para exponer choques culturales y la relación humano/naturaleza, manifestándose en piezas audiovisuales y hápticas. Ha sido parte de la residencia Google Artists + Machine Intelligence.</p>
                                        <p><a href="https://www.singingtextiles.com/" target="_blank">https://www.singingtextiles.com/</a></p>
                                    </div>
                                    <a className="btnback" href='/galeria/geografias'>
                                        <a data-page="p0"><img src={Image2} /></a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Emilio